// Colors variant type.
//
// Powerup extensively uses ChakraUI to build the components,
// and as per ChakraUI the color variants should be in the 50 - 900 range.
// - https://chakra-ui.com/docs/styled-system/theme#colors
interface Color {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

// Do NOT add any new color in this object, unless necessary. The colors should be defined in themeColors.
export const defaultColors = {
  purple: "#787BFF",
  blue: "#00B0D3",
  yellow: "#FFE620",
  orange: "#FF9500",
  red: "#F72A63",
}

// Theme colors for ChakraUI components, the ChakraUI internally expects
// colors variants in ranges 50 - 900.
const themeColors: Record<string, Partial<Color>> = {
  // Note, the Checkbox chakra component is reading white.500 so we need to set this object
  white: {
    500: "#FFFFFF",
    600: "#FFFFFF",
  },
  black: {
    500: "#1F1F1F",
    600: "#101010",
    700: "#000000",
  },
  gray: {
    50: "#FAFAFA", // lighter gray
    100: "#F3F3F3", // light gray
    300: "#B0B0B1", // soft gray
    400: "#878787", // medium gray
    600: "#6F7070", // dark gray
    800: "#424C47", // darker gray for button bg
  },
  green: {
    50: "#0CB560",
    500: "#0BB560",
  },
}

export const colors = { ...defaultColors, ...themeColors }
