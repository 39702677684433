"use client"

import { Theme } from "@/types/layoutConfig"
import { ReactNode, createContext, useContext } from "react"

type PowerupThemeContextProps = {
  activeTheme: Theme
  useColorModeValue: (lightModeColor: string, darkModeColor: string) => string
}

export const PowerupThemeContext =
  createContext<PowerupThemeContextProps | null>(null)

export const usePowerupThemeContext = () => {
  const context = useContext(PowerupThemeContext)

  if (!context) {
    throw new Error(
      "usePowerupThemeContext has to be used within <PowerupThemeProvider>"
    )
  }
  return context
}

type PowerupThemeProviderProps = {
  theme: Theme
  children: ReactNode
}

export const PowerupThemeProvider = ({
  theme,
  children,
}: PowerupThemeProviderProps) => {
  const useColorModeValue = (lightModeColor: string, darkModeColor: string) => {
    return theme === Theme.LIGHT ? lightModeColor : darkModeColor
  }
  const providerValue = {
    activeTheme: theme,
    useColorModeValue,
  }
  return (
    <PowerupThemeContext.Provider value={providerValue}>
      {children}
    </PowerupThemeContext.Provider>
  )
}
