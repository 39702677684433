/**
 * JSON.parse will throw an error if not passed valid JSON.
 * So we just return the value if it does.
 */
const parse = (value: string) => {
  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

export default parse
