import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  useDisclosure,
} from "@chakra-ui/react"
import { useParams, usePathname, useRouter } from "next/navigation"

import useUser from "@/hooks/useUser"
import { FeatureFlagKey, FeatureFlags } from "@/providers/DevFeatureFlagContext"
import { useMemo } from "react"

type DevFeatureFlagProps = {
  toggleFeatureFlag: (key: FeatureFlagKey) => void
  featureFlags: FeatureFlags
}
const DevFeatureFlag = ({
  toggleFeatureFlag,
  featureFlags,
}: DevFeatureFlagProps) => {
  const { isLoggedIn } = useUser()
  const router = useRouter()
  const pathname = usePathname()
  const params = useParams()

  const { onOpen, onClose, isOpen } = useDisclosure()

  const links = useMemo(
    () => [
      {
        href:
          isLoggedIn && params.id
            ? `/configurations/${params.id}/takeaway`
            : "/configurations/takeaway",
        label: "Go to Takeaway",
        pathKeyWord: "takeaway",
      },
      {
        href:
          isLoggedIn && params.id
            ? `/configurations/${params.id}/recommendation`
            : "/configurations/recommendation",
        label: "Go to Recommendation",
        pathKeyWord: "recommendation",
      },
      {
        href:
          isLoggedIn && params.id
            ? `/configurations/${params.id}/compliance-form-viewer`
            : "/configurations/compliance-form-viewer",
        label: "Go to Compliance Form Viewer",
        pathKeyWord: "compliance",
      },
    ],
    [isLoggedIn, params.id]
  )

  return (
    <Box
      position="fixed"
      margin="1rem"
      bottom={"6rem"}
      right={"1rem"}
      zIndex={100}
    >
      <Popover
        closeOnBlur={true}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Button
            borderRadius="1rem"
            borderColor={"gray"}
            borderWidth={1}
            onClick={onOpen}
          >
            🤖
          </Button>
        </PopoverTrigger>

        <PopoverContent>
          <Box background={"white"} padding="2rem">
            <Box display="flex" alignItems="left" flexDir={"column"}>
              {Object.entries(featureFlags).map(([key, value]) => {
                return (
                  <FormControl key={key}>
                    <FormLabel mb="0">{key}</FormLabel>
                    <Switch
                      size="sm"
                      colorScheme="teal"
                      isChecked={value}
                      onChange={() => {
                        toggleFeatureFlag(key as FeatureFlagKey)
                      }}
                    />
                  </FormControl>
                )
              })}
              {links.map((link, i) => {
                const { href, label, pathKeyWord } = link
                return (
                  <Button
                    key={i}
                    my={"1rem"}
                    px={"1rem"}
                    width={"fit-content"}
                    isDisabled={pathname.includes(pathKeyWord)}
                    onClick={() => {
                      router.push(href)
                      onClose()
                    }}
                    variant={"solid"}
                    border="1px solid black"
                    borderRadius={"20px"}
                    color="black.500"
                  >
                    {label}
                  </Button>
                )
              })}
            </Box>
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default DevFeatureFlag
