import { AnalyticsAction, AnalyticsObject } from "@/types/analytics"
import { AnalyticsBrowser } from "@segment/analytics-next"

type AnalyticsServiceProps = {
  segmentWriteKey?: string
}

export class AnalyticsService {
  private analytics: AnalyticsBrowser
  private isReady: boolean = false

  constructor({ segmentWriteKey }: AnalyticsServiceProps) {
    const analytics = new AnalyticsBrowser()

    if (!segmentWriteKey) {
      console.error("No Segment Analytics 'write key variable' was found.")
    }
    this.analytics = analytics.load({
      writeKey: segmentWriteKey || "Key is undefined",
    })
    this.isReady = true
  }

  public page = (pathname: string): void => {
    if (this.isReady) {
      this.analytics
        .page(AnalyticsObject.PAGE, {
          page: pathname,
        })
        .catch((error) => {
          console.error("Failed to send analytics event to Segment: ", error)
        })
    }
  }

  public trackEvent = (
    object: AnalyticsObject,
    action: AnalyticsAction,
    payload: any | undefined = {}
  ): void => {
    const event = `${object} | ${action}`

    if (this.isReady) {
      this.analytics.track(event, { ...payload }).catch((error) => {
        console.error("Failed to send analytics event to Segment: ", error)
      })
    }
  }
}
