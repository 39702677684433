import { useSession } from "next-auth/react"
import { useMemo } from "react"

/**
 * Convenience hook for getting the user and their login status.
 */
const useUser = () => {
  const { data, status } = useSession()
  const user = data?.user

  const isLoggedIn = useMemo(() => status === "authenticated", [status])

  return { user, isLoggedIn, status }
}

export default useUser
