export enum AnalyticsObject {
  PAGE = "Loaded A Website Page",
  HOME = "Home",
  APPLIANCE = "Appliance",
  RECOMMENDATION = "Recommendation",
  TAKEAWAY = "Takeaway",
  COMPLIANCE = "Compliance Form",
  CHART = "User Interacted With Chart",
}

export enum AnalyticsAction {
  SET = "Set",
  TOGGLED = "Toggled",
  SELECTED = "Selected",
  ENTERED = "Entered",
  ADDED = "Added",
  CREATED = "Created", // For additional appliances (e.g., heat pump 2)
  CREATED_CUSTOM = "Created custom", // For custom appliances (e.g., wine cooler)
  DELETED = "Deleted",
  UPDATED = "Updated",
  DOWNLOADED = "Downloaded",
  ERROR = "Error",
  GO_BACK = "Go back",
  SUBMIT = "Submit form",
  COMPLETED = "Completed",
  SHOWED = "Showed",
  MODIFIED_LIFESTYLE = "Modified lifestyle assumption",
}
