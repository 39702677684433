export type PublicConfigs = {
  PUBLIC_ENV?: Environments
  PUBLIC_SEGMENT_WRITE_KEY?: string
  PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID?: string
}

// sub paths of /configurations
export enum ConfigurationsURLS {
  NEW = "new",
  HOME_INFO = "home-info",
  APPLIANCES = "appliances",
  RECOMMENDATION = "recommendation",
}

export enum Environments {
  TEST = "test",
  DEVELOPMENT = "development",
  INTEGRATION = "integration",
  PRODUCTION = "production",
}
