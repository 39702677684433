import localFont from "next/font/local"

const graphik = localFont({
  src: [
    {
      path: "../../public/fonts/Graphik-Bold.woff2",
      weight: "700",
    },
    {
      path: "../../public/fonts/Graphik-Semibold.woff2",
      weight: "600",
    },
    {
      path: "../../public/fonts/Graphik-Medium.woff2",
      weight: "500",
    },
    {
      path: "../../public/fonts/Graphik-Regular.woff2",
      weight: "400",
    },
  ],
})

export const fonts = {
  heading: graphik.style.fontFamily,
  body: graphik.style.fontFamily,
}
