"use client"

import { ReactNode, createContext, useContext } from "react"

import useAnalyticsService, { UseAnalyticsService } from "./useAnalyticsService"

type AnalyticsContextProps = UseAnalyticsService

export const AnalyticsContext = createContext<AnalyticsContextProps | null>(
  null
)

/**
 * Utility hook that does a runtime check so we don't have to check for null when accessing the context
 */
export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)

  if (!context) {
    throw new Error(
      "useAnalyticsContext has to be used within <AnalyticsContext.Provider>"
    )
  }
  return context
}

type AnalyticsProviderProps = {
  segmentWriteKey?: string
  children: ReactNode
}
const AnalyticsProvider = ({
  segmentWriteKey,
  children,
}: AnalyticsProviderProps) => {
  const analyticsService = useAnalyticsService(segmentWriteKey)

  return (
    <AnalyticsContext.Provider value={analyticsService}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsProvider
