import { useState } from "react"

const useSaveProgress = () => {
  const [hasAnyUnsavedChanges, setHasAnyUnsavedChanges] = useState(false)
  const [onSaveCallback, setOnSaveCallback] = useState<
    (() => Promise<void>) | null
  >(null)
  const [projectName, setProjectName] = useState<string | undefined>()

  const providerValue = {
    hasAnyUnsavedChanges,
    setHasAnyUnsavedChanges,
    onSaveCallback,
    setOnSaveCallback,
    projectName,
    setProjectName,
  }
  return providerValue
}

export default useSaveProgress
