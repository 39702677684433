"use client"

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
} from "react"
import useSaveProgress from "./useSaveProgress"

type SaveProgressContextProps = {
  hasAnyUnsavedChanges: boolean
  setHasAnyUnsavedChanges: Dispatch<SetStateAction<boolean>>
  onSaveCallback: (() => Promise<void>) | null
  setOnSaveCallback: (fn: (() => () => Promise<void>) | null) => void
  projectName: string | undefined
  setProjectName: Dispatch<SetStateAction<string | undefined>>
}

export const SaveProgressContext =
  createContext<SaveProgressContextProps | null>(null)

export const useSaveProgressContext = () => {
  const context = useContext(SaveProgressContext)

  if (!context) {
    throw new Error(
      "useSaveProgressContext has to be used within <SaveProgressProvider>"
    )
  }
  return context
}

type SaveProgressProviderProps = {
  children: ReactNode
}

export const SaveProgressProvider = ({
  children,
}: SaveProgressProviderProps) => {
  const saveProgressProvider = useSaveProgress()

  return (
    <SaveProgressContext.Provider value={saveProgressProvider}>
      {children}
    </SaveProgressContext.Provider>
  )
}
