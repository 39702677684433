"use client"

import { CacheProvider } from "@chakra-ui/next-js"
import { ChakraProvider } from "@chakra-ui/react"
import { LDContext, LDProvider } from "launchdarkly-react-client-sdk"
import { SessionProvider } from "next-auth/react"
import { useEffect, useState } from "react"

import { appTheme } from "@/config/theme/index"
import { JWT_REFRESH_TIMEOUT } from "@/constants/JWT"
import AnalyticsProvider from "@/providers/AnalyticsContext"
import DevFeatureFlagProvider from "@/providers/DevFeatureFlagContext"
import { PowerupThemeProvider } from "@/providers/PowerupThemeContext"
import { SaveProgressProvider } from "@/providers/SaveProgressContext"
import { Environments, PublicConfigs } from "@/types/appConfig"
import { Theme } from "@/types/layoutConfig"

type ProvidersProps = {
  children: React.ReactNode
}
const Providers = ({ children }: ProvidersProps) => {
  const [ip, setIp] = useState<string | undefined>(undefined)
  useEffect(() => {
    // Update favicons for non-Prod environments
    if (
      location &&
      (location.hostname === "localhost" ||
        location.hostname.includes("dev.span.tools"))
    ) {
      document
        .querySelector("link[rel='shortcut icon']")
        ?.setAttribute("href", "/images/dev_favicon.ico")
    } else if (location && location.hostname.includes("int.span.tools")) {
      document
        .querySelector("link[rel='shortcut icon']")
        ?.setAttribute("href", "/images/int_favicon.ico")
    }
  }, [])

  // Get ip address of device in order to use as a feature flag for launch darkly
  useEffect(() => {
    const getIPAddress = async () => {
      const response = await fetch("https://api.ipify.org?format=json")
      return response.json()
    }

    try {
      getIPAddress().then((data) => {
        setIp(data.ip)
      })
    } catch (error) {
      console.error(`There was an error when fetching ip address. ${error}`)
    }
  }, [])

  const [publicConfigs, setPublicConfigs] = useState<PublicConfigs>({})

  useEffect(() => {
    // Get client-side environment variables
    const getPublicConfigs = async () => {
      const response = await fetch("/api/app-config", {
        method: "GET",
      })
      return response.json()
    }
    getPublicConfigs()
      .then((data) => {
        if (Object.keys(data).length === 0) {
          console.error("No configurations were passed to the client.")
        }

        console.log(`Current environment: ${data?.PUBLIC_ENV}`)
        setPublicConfigs(data)
      })
      .catch((error) => {
        console.error(
          `There was an error when fetching public configs from the API route. ${error}`
        )
      })
  }, [])

  const isProduction = publicConfigs.PUBLIC_ENV === Environments.PRODUCTION

  const launchDarklyContext: LDContext | undefined =
    publicConfigs?.PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID
      ? { kind: "user", key: "anonymous-user-key", ip }
      : undefined

  return (
    <SessionProvider refetchInterval={JWT_REFRESH_TIMEOUT}>
      <CacheProvider>
        <ChakraProvider theme={appTheme}>
          <PowerupThemeProvider theme={Theme.DARK}>
            <AnalyticsProvider
              segmentWriteKey={
                isProduction
                  ? publicConfigs?.PUBLIC_SEGMENT_WRITE_KEY
                  : undefined
              }
            >
              <DevFeatureFlagProvider environment={publicConfigs?.PUBLIC_ENV}>
                <LDProvider
                  clientSideID={
                    publicConfigs?.PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID ||
                    "no-client-side-id-found"
                  }
                  deferInitialization // Launch Darkly initialization is deferred until a context is provided
                  context={launchDarklyContext}
                >
                  <SaveProgressProvider>{children}</SaveProgressProvider>
                </LDProvider>
              </DevFeatureFlagProvider>
            </AnalyticsProvider>
          </PowerupThemeProvider>
        </ChakraProvider>
      </CacheProvider>
    </SessionProvider>
  )
}

export default Providers
