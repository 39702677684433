import { usePathname } from "next/navigation"
import { useEffect, useMemo } from "react"

import { AnalyticsService } from "@/utils/AnalyticsService"

export type UseAnalyticsService = {
  analyticsService?: AnalyticsService
}

/**
 * Primary interface for using the AnalyticsService class.
 * - Instantiates the AnalyticsService for a user session
 * - Also tracks page changes with a useEffect hook
 */
const useAnalyticsService = (segmentWriteKey?: string): UseAnalyticsService => {
  const analyticsService = useMemo(() => {
    if (segmentWriteKey) {
      return new AnalyticsService({ segmentWriteKey })
    } else {
      return undefined
    }
  }, [segmentWriteKey])

  const pathname = usePathname()

  useEffect(() => {
    if (analyticsService) {
      analyticsService.page(pathname)
    }
  }, [pathname, analyticsService])

  return {
    analyticsService,
  }
}

export default useAnalyticsService
