/**
 * JWT/Session expiration timeout in seconds. Set to 1 day.
 */
export const EXPIRATION_TIMEOUT = 60 * 60 * 24

/**
 * JWT refresh timeout in seconds. Set to 5 mins less than JWT_EXPIRATION_TIMEOUT
 * so that new token will be fetched before expired existing.
 * Set to 55 mins, i.e. 5 mins before expiration.
 */
export const JWT_REFRESH_TIMEOUT = 55 * 60

/**
 * 5 minutes in milliseconds
 */
export const FIVE_MINUTES = 5 * 60 * 1000
