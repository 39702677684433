import { useCallback, useEffect, useRef, useState } from "react"

import parse from "@/utils/parseJSON"

/**
 * @see - https://stackoverflow.com/questions/72961647/how-to-implement-a-uselocalstorage-hook-in-next-js
 *
 * A local storage interface that returns default values when the page is SSR, and local storage values
 * when the page is hydrating on the client. This avoids server-client mismatch issues that are common in
 * hybrid SSR/CSR frameworks.
 *
 *
 * Instantiate separate hooks for each value you want to track in local storage
 * @example
 * const { item: value1, setItem: setValue1 } = useLocalStorage("value1", "foo")
 * const { item: value2, setItem: setValue2 } = useLocalStorage("value2", "bar")
 */
function useLocalStorage<T>(key: string, defaultValue?: T) {
  const isMounted = useRef(false)
  const [value, setValue] = useState<T | undefined>(defaultValue)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const removeItem = useCallback(() => {
    setValue(undefined)
  }, [])

  useEffect(() => {
    // 1. First effect to run. Sometimes the hook is instantiated without a defaultValue which may get defined later.
    // If the defaultValue comes in later, set it.
    if (!value) {
      setValue(defaultValue)
    }
  }, [value, defaultValue])

  // Instantiation and clean up hook that should only call once when the Component mounts.
  useEffect(() => {
    // 2. Check if item exists in local storage and set to React.state.
    try {
      const item = window.localStorage.getItem(key)

      if (item) {
        setValue(parse(item))
      }
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)

    return () => {
      // 5. When the Component unmounts, reset isMounted to false
      isMounted.current = false
    }
  }, [key])

  // Main logic hook
  // Calling setItem should trigger this useEffect that maps React.state to local storage
  useEffect(() => {
    if (isMounted.current === true) {
      // 4. If the Component is mounted, map changes in React.state to local storage
      if (value === undefined) {
        window.localStorage.removeItem(key)
      } else {
        window.localStorage.setItem(key, JSON.stringify(value))
      }
    } else {
      // 3. Component has not mounted yet. Effectively short circuits the preceding "if", so as to avoid a race
      // condition in the initialization step where we override localStorage with the default value.
      isMounted.current = true
    }
  }, [key, value])

  return {
    item: value,
    setItem: setValue,
    removeItem,
    isLoading,
  }
}

export default useLocalStorage
