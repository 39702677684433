"use client"

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
} from "react"

import DevFeatureFlag from "@/components/DevFeatureFlag"
import useLocalStorage from "@/hooks/useLocalStorage"
import { Environments } from "@/types/appConfig"

// Add feature flags with default values here
const FEATURE_FLAGS = {
  "Selected appliances": false,
  "Appliance details": false,
  "SPAN Checks": false,
  "SPAN NEC Compliance Results": false,
  "SPAN NEC Compliance PDFs": false,
  "Standard NEC Compliance Results": false,
  "Standard NEC Compliance PDFs": false,
  "Scenario Chart Values": false,
}

type DevFeatureFlagContextProps = {
  toggleFeatureFlag: (key: FeatureFlagKey) => void
  devFeatureFlags: FeatureFlags
}

const DefaultFeatureFlagContext = {
  devFeatureFlags: FEATURE_FLAGS,
  toggleFeatureFlag: () => {},
}
export const DevFeatureFlagContext = createContext<DevFeatureFlagContextProps>(
  DefaultFeatureFlagContext
)

type DevFeatureFlagProviderProps = {
  children: ReactNode
  environment?: string
}

/**
 * Utility hook that does a runtime check so we don't have to check for null when accessing the context
 */
export const useDevFeatureFlag = () => {
  const context = useContext(DevFeatureFlagContext)

  if (!context) {
    throw new Error(
      "useDevFeatureFlag has to be used within <DevFeatureFlagContext.Provider>"
    )
  }

  return context
}

export type FeatureFlagKey = keyof typeof FEATURE_FLAGS

export type FeatureFlags = {
  [key in FeatureFlagKey]: boolean
}
/**
 * A feature flagging system for development purposes.
 * Add feature flags to the FEATURE_FLAG object with default values.
 *
 * Toggle your feature flags on/off by clicking on the Feature Flag UI in the bottom right-hand of the app.
 * @note - This Feature Flag UI should only show up in development and integration. NOT in production.
 *
 * Consume feature flags via the useDevFeatureFlag hook
 * @example
 * const { devFeatureFlags } = useDevFeatureFlag()
 *
 * const value = devFeatureFlags.myFeature ? 1 : 0
 *
 */
const DevFeatureFlagProvider = ({
  children,
  environment,
}: DevFeatureFlagProviderProps) => {
  // Using casting here because item could be undefined, but in this case, we know it's definitely defined.
  const { item, setItem } = useLocalStorage("feature-flags", FEATURE_FLAGS) as {
    item: FeatureFlags
    setItem: Dispatch<SetStateAction<FeatureFlags>>
  }

  const toggleFeatureFlag = useCallback(
    (key: FeatureFlagKey) => {
      setItem((prev) => {
        return {
          ...prev,
          [key]: !prev[key],
        }
      })
    },
    [setItem]
  )

  const isDevFeatureFlagEnabled =
    environment === Environments.DEVELOPMENT ||
    environment === Environments.INTEGRATION ||
    environment === Environments.TEST

  return (
    <DevFeatureFlagContext.Provider
      value={{ devFeatureFlags: item, toggleFeatureFlag }}
    >
      {isDevFeatureFlagEnabled ? (
        <DevFeatureFlag
          toggleFeatureFlag={toggleFeatureFlag}
          featureFlags={item}
        />
      ) : null}

      {children}
    </DevFeatureFlagContext.Provider>
  )
}

export default DevFeatureFlagProvider
